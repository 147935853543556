import React, { Component } from "react";
import ReactDOM from 'react-dom';
import _ from 'underscore';
import { connect } from "react-redux";
import { getData, getPages, setType, getCategories,getVotes } from "../actions/index";
import * as d3 from "d3";
import "../css/graph.css";


    const columns = ["original","conference"]



export class Graph extends Component {

  state = {
    elementHeight: 0,
    elementWidth:0
  };
  constructor(props) {
    super(props);
    // this.drawChart = this.drawChart.bind(this)

  }

  componentDidMount() {
    this.props.getVotes()


    this.setState({ elementHeight: this.divRef.clientHeight });
    this.setState({ elementWidth: this.divRef.clientWidth });






  }

  componentDidUpdate (newProps) {

    if( newProps.votes !== this.props.votes ){
      this.setState({ elementHeight: this.divRef.clientHeight });
      this.setState({ elementWidth: this.divRef.clientWidth });

      //this.drawChart();
    }

    if(this.props.animate === true){

      //
    }

    if (this.state.elementWidth !== undefined){
      this.drawChart();
    }

  }




  drawChart() {




  // only return answers for this question number
let votes = this.props.votes
let NewProjects=[]
//


var result = _.map(this.props.votes, function(project){
     var length = _.reject(votes, function(el){
           return (el.project.indexOf(project.project) < 0);
     }).length;
     // return {key:project.key,original:length,conference:"",total:"",id:project.id};
     return {id:project.project, count:length };
});


votes = _.uniq(result,'id')

for(let project of this.props.projects){
    let A = {key:project.project_short_title,original:0,conference:0,total:"",id:project.id}
  for(let vote of votes){
    if(project.id === vote.id){
       A = {key:project.project_short_title,original:vote.count,conference:0,total:"",id:project.id}

    }
  }
    NewProjects.push(A)
}

//
// console.log(NewProjects)





//   let QNum =  parseInt(this.props.QNum)
//      answers = _.filter(this.props.answers, function(n){
//
//     return n.question.Question_number === QNum;
//
//          });
//
// let QuestionTitle = answers[0].question.Question_title


//     let newAnswers = []
//
// //fix answers into the kind of array d3 is expecting
//     for (let answer of answers) {
//       let A = {key:answer.Key,original:answer.original_value.toString(),conference:answer.conference_value.toString(),total:answer.original_value+answer.conference_value}
//       newAnswers.push(A)
//     }
          //get a concainter to put our graph in
          let container = this.refs.graph
          container.innerHTML= ""


          // Make a new 'stackedVerticalBarChart' object and pass the data to it
          // this will run all the code outside of the function called 'chart'.
          // As soon as 'chart' is called, the graph will be initiated
          var myVertBarChart1 =  this.stackedVerticalBarChart().data(NewProjects);

          var width = this.state.elementWidth-100;
          var height = this.state.elementWidth/2.5;

          //set width and height of this graph object to the same a its parents
          if(width > 0 && height > 0)
          {
            myVertBarChart1.width(width).height(height);
          }

          //use this d3 expresssion to bind the container and the
          //pre prepared chart object and call the 'chart' function
          //inside the 'stackedVerticalBarChart'
          d3.select(container)
            .call(myVertBarChart1);

  }


  stackedVerticalBarChart() {
    // All options that should be accessible to caller

    var data;
    var width = 1000;
    var height = 500;
    var projectsLength = this.props.projects.length


    var updateWidth;
    var updateHeight;
    var updateColor;
    var updateData;
    var updateSingleBar;
    var color = "#ffffff";

    function chart(selection) {

      selection.each(function() {


                var dom = d3.select(this);



        // var data2 = data.map(function(d, i, columns) {
        //   for (i = 1,  t = 0; i < columns.length; ++i)
        //   {
        //     t = parseFloat(d["original"]) + parseFloat(d["conference"]);
        //     d.total = t;
        //     return d;
        //   }
        // })
        var data2 = data



        var margin = {top: 20, right: 20, bottom: 30, left: 40};
        width = width - (margin.left + margin.right)
        height = height - (margin.top + margin.bottom);
        var keys = columns

        // set x scale
        var x = d3.scaleBand()
            .rangeRound([0, width])
            .paddingInner(0.05)
            .align(0.1);

        // set y scale


          var original = d3.max(data2, function(d) { return +d.original;} );
          // var conference = d3.max(data2, function(d) { return +d.conference;} );
          var numbers = [Math.ceil(original)]

          var max = Math.max.apply(null, numbers)

          // var y = d3.scaleLinear()
          //     .rangeRound([height, 0]);

          var y = d3.scaleLinear().domain([0, max]).rangeRound([height, 0]);

        // set the colors
        var z = d3.scaleOrdinal()
            .range(["#ffe512", "rgb(240, 156, 178)"]);

        var keys2 = data2.map(function(d) {
          return d.key;
        })

        x.domain(data2.map(function(d) {
          return d.key;
        }));
        y.domain([0, max]);
        z.domain(keys);


        var svg = dom.append('svg');
        svg
          .attr('width', width + 50)
          .attr('height', height + 50);

        var g = svg.append("g").attr("transform", "translate(" + margin.left + "," + margin.top + ")");

        g.append("g")
          .selectAll("g")
          .data(d3.stack().keys(keys)(data2))
          .enter()
          .selectAll("rect")
          .data(function(d) { return d; })
          .enter().append("rect")
            .attr("fill", function(d, i) {
              if(d[0] == 0)
              {
                return z("original");
              }
              else
              {
                return z("conference");
              }

            })
            .attr("x", function(d) {
              return x(d.data.key);
            })

            .attr("y", function(d){
              return height;
            })
            .attr("height", 0)
            .attr("width", x.bandwidth())
            .style("z-index", function(d, i){

            })


            .on("mouseover", function() { tooltip.style("display", null); })
            .on("mouseout", function() { tooltip.style("display", "none"); })
            .on("mousemove", function(d) {

              var xPosition = d3.mouse(this)[0] - 15;
              var yPosition = d3.mouse(this)[1] - 5;
              tooltip.attr("transform", "translate(" + xPosition + "," + yPosition + ")");
              tooltip.select("text").text(Math.round( (d[1]-d[0]) * 10) / 10);
            })
            .transition()
            .delay(function(d, i) {
              return i * 100;
            })
            .duration(1000)
            .ease(d3.easeBounceOut)
            .attr("y", function(d){
              return height - (y(d[0]) - y(d[1]));
            })
            .attr("height", function(d) { return y(d[0]) - y(d[1]); })
            .attr("width", x.bandwidth())




        svg.selectAll("text")
            .data(data)
            .enter()
            .append("text")
            .attr("transform", "rotate(-90)")
            .style("text-anchor", "start")
            .attr("font-size", function (d) { if(d.original < 5){return "10px" }else{ return "20px"}; })
            .attr("font-family", "aecom_sansbold")
            .text(function (d) { if(d.original < max/2 ){return "" }else{ return d.key}; })
            .attr("x", -height )
            .attr("height", "300px" )
                .attr("y", function(d){
                  return x(d.key)+80;
                })

            .style("fill", "#000000")
            .style("word-wrap", "normal")

            // svg.selectAll("text")
            //     .data(data)
            //     .enter()
            //     .append("text")
            //     .attr("transform", "rotate(-90)")
            //     .style("text-anchor", "start")
            //     .attr("font-size", "20px")
            //     .attr("font-family", "aecom_sansbold")
            //     .text(function (d) { return d.key; })
            //     .attr("x", -height )
            //         .attr("y", function(d){
            //           return x(d.key)+90;
            //         })
            //
            //     .style("fill", "white");



        svg.selectAll("rect")
          .each(function(d, i, a){
            var datum = d3.select(a[i]).datum();
            if(i < 4)
            {
              if(parseFloat(datum.data.original) < parseFloat(datum.data.conference))
              {
                a[i].parentNode.appendChild(a[i]);
              }
            }
          })
        var xAxis = d3.axisBottom(x).tickFormat(function(d){
          return d;
        });
        g.append("g")
            .attr("class", "axis")
            .attr("transform", "translate(0," + height + ")")
            .call(xAxis)
            .selectAll(".tick text")
            .call(wrap, (width / projectsLength)-200)
            .attr("font-size", width/135 + "px")
            .attr("font-family", "aecom_sansbold")
//
// console.log(max)
            function wrap(text, width) {
              // console.log(text, width)
            text.each(function() {
              var text = d3.select(this),
                  words = text.text().split(/\s+/).reverse(),
                  word,
                  line = [],
                  lineNumber = 0,
                  lineHeight = 1.1, // ems
                  y = text.attr("y"),
                  dy = parseFloat(text.attr("dy")),
                  tspan = text.text(null).append("tspan").attr("x", 0).attr("y", y).attr("dy", dy + "em")
              while (word = words.pop()) {
                line.push(word)
                tspan.text(line.join(" "))
                if (tspan.node().getComputedTextLength() > width) {
                  line.pop()
                  tspan.text(line.join(" "))
                  line = [word]
                  tspan = text.append("tspan").attr("x", 0).attr("y", y).attr("dy", `${++lineNumber * lineHeight + dy}em`).text(word)
                }
              }
            })
            }




        var yAxis = d3.axisLeft(y).ticks(max)



        g.append("g")
            .attr("class", "axis")
            .call(yAxis)
            .attr("font-size", width/120 + "px")
            .attr("font-family", "aecom_sansbold")
            .attr("text-anchor", "end")
            .attr("dx", -20)

          .append("text")
            .attr("x", 2)
            .attr("y", y(y.ticks().pop()) + 0.5)
            .attr("dy", "0.32em")
            .attr("fill", "#fff")
            .attr("font-weight", "bold")
            .attr("font-size", width/10 + "px")
            .attr("font-family", "aecom_sansbold")
            .attr("text-anchor", "end");


        // Prep the tooltip bits, initial display is hidden
         var tooltip = svg.append("g")
          .attr("class", "chart-tooltip")
          .style("display", "none");

        tooltip.append("rect")
          .attr("width", 60)
          .attr("height", 20)
          .attr("fill", "white")
          .style("opacity", 0.5);

        tooltip.append("text")
          .attr("x", 30)
          .attr("dy", "1.2em")
          .style("text-anchor", "middle")
          .attr("font-size", "12px")
          .attr("font-weight", "bold");



        // update functions
        updateWidth = function() {

        };
        updateHeight = function() {

        };
        updateColor = function(value){
          color = value;
        };
        updateData = function() {

        }
      });
    }
    chart.width = function(value) {
      if (!arguments.length) return width;
        width = value;
      if (typeof updateWidth === 'function') updateWidth();
        return chart;
    };
    chart.height = function(value) {
      if (!arguments.length) return height;
        height = value;
      if (typeof updateHeight === 'function') updateHeight();
        return chart;
    };
    chart.color = function(value) {
      if (!arguments.length) return color;
        color = value;
      if (typeof updateColor === 'function') updateColor();
        return chart;
    };
    chart.data = function(value) {
    if (!arguments.length) return data;
      data = value;
      if (typeof updateData === 'function') updateData();
        return chart;
    };
    chart.toggleBar = function(barName, ele) {
      if (!arguments.length) return data;

      if (typeof updateSingleBar === 'function') updateSingleBar(barName, ele);
        return chart;
    };
    return chart;
  }






  render() {

    let BlankStyle = {
        width:this.state.elementWidth -100 +"px",
        height:this.state.elementWidth/2.5+"px"
      }
//<svg width={this.state.elementWidth-100} height={this.state.elementWidth/2.3} ref="graph"></svg>
  if(this.state.elementWidth !== undefined){
    return (
        <div className="bar-container category__nominees-list" ref={element => this.divRef = element} >
<svg width={this.state.elementWidth-100} height={this.state.elementHeight} ref="graph"></svg>


        </div>
    );
  }else{
    return (
    <div style={BlankStyle}></div>
  )
  }

  }
}

function mapStateToProps(state) {
  return {
    categories: state.categories,
    votes: state.votes,
    pages: state.pages,
    projects: state.projects,
    app_type: state.app_type
  };
}
export default connect(
  mapStateToProps,
  { getData, getPages, setType, getCategories,getVotes }
)(Graph);
