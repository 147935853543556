import React, { Component } from "react";
import { connect } from "react-redux";
import { getData, getPages, setType, getCategories } from "../actions/index";
import { Route, withRouter } from "react-router-dom"
import { Flipped } from "react-flip-toolkit"
import Video from "./WistiaEmbed.js"




export class VideoCheck extends Component {


  constructor(props) {
    super(props);
        // this.update = this.update.bind(this)
  }


  render() {


    var current = new Date();
    var expiry  = new Date("May 10, 2019 12:00:00")

    if(current.getTime()>expiry.getTime()){
      return(
        <div>
        <div data-fade-in>
        <Video hashedId={this.props.project.video} playerColor="#ffe512" />
        </div>

      </div>
      )
    }else{
      return(
        <div>
        <img data-fade-in className="responsive-img" src={`${this.props.strapiRoot + this.props.project.image.url}`} border="0"/>
      </div>
      )
    }



  return(
    <div>
    <div data-fade-in>
    <Video hashedId={this.props.project.video} playerColor="#ffe512" />
    </div>
    <img data-fade-in className="responsive-img" src={`${this.props.strapiRoot + this.props.project.image.url}`} border="0"/>
</div>
  )


  }
}

function mapStateToProps(state) {
  return {
    categories: state.categories,
    pages: state.pages,
    projects: state.projects,
    app_type: state.app_type,
    strapiRoot: state.strapiRoot
  };
}
export default withRouter(connect(
  mapStateToProps,
  { getData, getPages, setType, getCategories  }
)(VideoCheck));
