import React, { Component } from "react";
import anime from "animejs"
import { Flipped } from "react-flip-toolkit"


export class ProjectsCard extends Component {

  constructor(props) {
    super(props);
}


  onStart = (el, prevLocation, currentLocation) => {
    // console.log("onStart card",el, prevLocation, currentLocation, this.props.setKey )
    if (
      prevLocation.location.pathname.match(this.props.setKey) &&
      currentLocation.location.pathname === "/"
    ) {
      ;[...el.querySelectorAll("[data-fade-in]")].forEach(
        el => (el.style.opacity = "0")
      )
      el.style.zIndex = "5"
    }
  }


  onComplete = (el, prevLocation, currentLocation) => {
    // console.log("onComplete",el, prevLocation, currentLocation)
    if (
      currentLocation.location.pathname === "/" &&
      prevLocation.location.pathname.match(this.props.setKey)
    ) {
      anime({
        targets: el.querySelectorAll("[data-fade-in]"),
        opacity: [0, 1],
        translateY: [15, 0],
        delay: (el, i) => i * 70 + 300,
        easing: "easeOutSine",
        duration: 350
      })
      el.style.zIndex = ""
    }
  }

  onDelayedAppear = (el, index) => {
      // console.log("onDelayedAppear",el)
    anime({
      targets: el,
      opacity: [0, 1],
      scale: [0.9, 1],
      easing: "easeOutSine",
      delay: index * 40,
      duration: 400
    })
  }

  onExit = (el, index, removeElement) => {
    // console.log("onExit",el)
    anime({
      targets: el,
      opacity: 0,
      scale: 0.9,
      easing: "easeOutSine",
      duration: 400,
      delay: index * 40,
      complete: removeElement
    })
  }

  navigate = () => {
    this.props.navigate(this.props.setKey,this.props.category)
  }

  shouldFlip = (prev, current) => {
    // console.log("shouldFlip", prev.location, current.location, this.props.category, this.props.project.categories.Category_title)
    const sort1 =
      current.location.search.match(/sort=([^&]+)/) &&
      current.location.search.match(/sort=([^&]+)/)[1]
    const sort2 =
      prev.location.search.match(/sort=([^&]+)/) &&
      prev.location.search.match(/sort=([^&]+)/)[1]
// console.log(sort1, sort2 )
//       console.log(sort1 === sort2 )
    return sort1 === sort2
  }


  render() {
  const { match, location, history } = this.props
    // this.props.project.title.replace(new RegExp(" ", 'g'), "_").replace(new RegExp("/", 'g'), "")
  const { setKey } = this.props
let cardStyle = {
  backgroundImage: `url(${this.props.strapiRoot + this.props.project.image.url})`
};

    return (
      <Flipped
        flipId={setKey}
        onStartImmediate={this.onStart}
        onComplete={this.onComplete}
        onDelayedAppear={this.onDelayedAppear}
        onExit={this.onExit}
        shouldInvert={this.shouldFlip}
      >




              <div className="tile-wrapper" onClick={this.navigate}>
              <Flipped inverseFlipId={setKey}>
              <Flipped
                    flipId={`${setKey}-count`}
                    translate
                    shouldFlip={this.shouldFlip}
                  >
                  <div className="tile nominee" ref={el => (this.description = el)}>
                      <div className="tile__container" style={ cardStyle }>
                          <div className="tile__overlay">
                              <div className="tile__content">
                                  <h3 data-fade-in className="tile__title">{this.props.project.title}</h3>
                              </div>
                          </div>
                      </div>
                  </div>
                  </Flipped>
                  </Flipped>
              </div>


      </Flipped>
    );

  }
}

export default ProjectsCard;
