import React, { Component } from "react";

import { connect } from "react-redux";
import { getData, getPages, setType, getCategories } from "../actions/index";

import {BrowserRouter as Router, Switch, Route, Redirect} from "react-router-dom";

import Graph from "./graphdash"




export class Dashboard extends Component {



  constructor(props) {
    super(props);


    this.state = {
      navigate: false,
      showgraph:false

  }

    this.escFunction = this.escFunction.bind(this);

  }

  componentWillReceiveProps(nextProps) {

  if(nextProps.value !== this.props.value) {
    this.setState({value: nextProps.value});
  }

}





  componentDidMount() {
            this.props.getData();
               document.addEventListener("keydown", this.escFunction, false);
  }

  escFunction(event){

    let self = this
    console.log(event.keyCode)
     if(event.keyCode === 32) {
       if(this.state.showgraph === false){
     this.setState({showgraph: true});
   }else{
     this.setState({showgraph: false});
   }
     }
   }

   componentWillUnmount(){
     document.removeEventListener("keydown", this.escFunction, false);
   }



  render() {
if(this.state.showgraph){
  return (
    <div>
    <Graph Qtitle="The Peoples Choice"  Votes={this.props.votes}  />
     </div>
  );
}else{
  return (
    <div className="bar-container category__nominees-list">
    <div className="awards-logo">
    <h1 className="awards__title">UK &amp; Ireland<br/><span>Excellence<br/>Awards</span><br/>2019</h1>
    </div>
        <h1 className="peopleChoice">The People's choice award</h1>
     </div>
  );

}

}



}

function mapStateToProps(state) {
  return {
    votes: state.votes,
    categories: state.categories,
    pages: state.pages,
    projects: state.projects,
    app_type: state.app_type
  };
}
export default connect(
  mapStateToProps,
  { getData, getPages, setType, getCategories }
)(Dashboard);
