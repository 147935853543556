import React, { Component } from "react";
import { connect } from "react-redux";
import { getData, getPages, setType, getCategories } from "../actions/index";
import { Route, withRouter } from "react-router-dom"
import _ from 'underscore';
import anime from "animejs"
import { Flipped } from "react-flip-toolkit"


import "../css/app.css";



export class Page extends Component {


  constructor(props) {
    super(props);
        // this.update = this.update.bind(this)
  }

  state = {
    navigate: false,
  };

  componentDidMount() {

  }


    onStart = (el, prevLocation, currentLocation) => {
      if (
        prevLocation.location.pathname.match(this.props.setKey) &&
        currentLocation.location.pathname === "/"
      ) {
        ;[...el.querySelectorAll("[data-fade-in]")].forEach(
          el => (el.style.opacity = "0")
        )
        el.style.zIndex = "5"
      }
    }


    onComplete = (el, prevLocation, currentLocation) => {
      if (
        currentLocation.location.pathname === "/" &&
        prevLocation.location.pathname.match(this.props.setKey)
      ) {

        anime({
          targets: el.querySelectorAll("[data-fade-in]"),
          opacity: [0, 1],
          translateY: [15, 0],
          delay: (el, i) => i * 70 + 300,
          easing: "easeOutSine",
          duration: 350
        })
        el.style.zIndex = ""
      }
    }

    onDelayedAppear = (el, index) => {
      anime({
        targets: el,
        opacity: [0, 1],
        scale: [0.9, 1],
        easing: "easeOutSine",
        delay: index * 40,
        duration: 400
      })
    }

    onExit = (el, index, removeElement) => {
      anime({
        targets: el,
        opacity: 0,
        scale: 0.9,
        easing: "easeOutSine",
        duration: 400,
        delay: index * 40,
        complete: removeElement
      })
    }

    shouldFlip = (prev, current) => {
      const sort1 =
        current.location.search.match(/sort=([^&]+)/) &&
        current.location.search.match(/sort=([^&]+)/)[1]
      const sort2 =
        prev.location.search.match(/sort=([^&]+)/) &&
        prev.location.search.match(/sort=([^&]+)/)[1]

      return sort1 === sort2
    }


    navigate = () => {
      this.props.navigate(`page/${this.props.page}`)
    }




  render() {
    const { match, location, history } = this.props

    const { setKey } = this.props
    const focusedSet = this.props.location.pathname.split(/\//g)[2]
    let page = this.props.page
    page = _.find(this.props.pages, function (o) { return o.title === page.replace(new RegExp("_", 'g'), " ") })
    let set = page.title.replace(new RegExp(" ", 'g'), "_")

    let cardStyle = {
      backgroundImage: `url(${this.props.strapiRoot + page.image.url})`
    };


if(this.props.page === "David_Barwell"){
    if (this.props.page.replace(new RegExp(" ", 'g'), "_").replace(new RegExp("/", 'g'), "") === focusedSet) return <div key={set} />
    return (
      <Flipped
        flipId={"page/" + this.props.page.replace(new RegExp(" ", 'g'), "_")}
        onStartImmediate={this.onStart}
        onComplete={this.onComplete}
        onDelayedAppear={this.onDelayedAppear}
        onExit={this.onExit}
        shouldInvert={this.shouldFlip}
      >
      <div className="tile david-message" data-key="david"  onClick={this.navigate}>
      <Flipped inverseFlipId={this.props.page.replace(new RegExp(" ", 'g'), "_")}>
      <Flipped
            flipId={`${this.props.page.replace(new RegExp(" ", 'g'), "_")}-count`}
            translate
            shouldFlip={this.shouldFlip}
          >
          <div className="tile__container message" style={cardStyle}>
              <div className="tile__overlay">
                  <div className="tile__content">
                      <h2 data-fade-in className="tile__title">David Barwell</h2>
                      <p data-fade-in className="tile__teaser">AECOM’s UK and Ireland Awards are all about celebrating the fantastic work we have done together over the past 12 months.</p>
                  </div>
              </div>
          </div>
          </Flipped>
          </Flipped>
      </div>
</Flipped>

    );
  }else{
      return (
    <div></div>
  )
  }


  }
}

function mapStateToProps(state) {
  return {
    categories: state.categories,
    pages: state.pages,
    projects: state.projects,
    app_type: state.app_type,
    strapiRoot: state.strapiRoot
  };
}
export default withRouter(connect(
  mapStateToProps,
  { getData, getPages, setType, getCategories  }
)(Page));
