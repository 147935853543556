import React, { Component } from "react";
import { connect } from "react-redux";
import { getData, setCategory } from "../actions/index";
import _ from 'underscore';
import __ from 'lodash';
import ProjectCard from "./projectscard.js"
import DetailPage from "./detailpage.js"
import { Route, withRouter } from "react-router-dom"
import qs from "qs"

const FilteredProjects = []
const projectName = []

export class Projectsgrid extends Component {

  constructor(props) {
    super(props);
  }


  componentDidMount() {


this.FilteredProjects = []
this.projectName = []
  }
    navigate = (set, category) => {
      this.props.setCategory(category)
      this.props.history.push({
        pathname: `/${set.replace(/\s/g, "-")}`,
        search: this.props.location.search
      })
    }


  render() {
      const focusedSet = this.props.location.pathname.split(/\//g)[1]
this.FilteredProjects = [];
this.projectName =[]
    for (let project of this.props.projects){
      projectName.push(project.title)
      for (let proj of project.categories){
      if(proj.Category_title === this.props.category){
        this.FilteredProjects.push(project);
      }
    }
    }



const { match, location, history } = this.props





    return (
      <div className="category__container">
        <div className="category__nominees-list">

        {this.FilteredProjects.map((set, el, index) => {
                  if (set.title.replace(new RegExp(" ", 'g'), "_").replace(new RegExp("/", 'g'), "") === focusedSet) return <div key={set} />
                  // console.log("show filtered" )
                  return (
                    <ProjectCard
                    key={set.title.replace(new RegExp(" ", 'g'), "_").replace(new RegExp("/", 'g'), "")}
                    projects={this.projectName}
                    category = {this.props.category}
                    setKey={set.title.replace(new RegExp(" ", 'g'), "_").replace(new RegExp("/", 'g'), "")}
                    navigate={this.navigate}
                    strapiRoot={this.props.strapiRoot}
                    project={set}/>
                  )
                })}

      </div>

      </div>
    );

  }
}
function mapStateToProps(state) {
  return {
    projects: state.projects,
    strapiRoot : state.strapiRoot,
    selectedCategory : state.category
  };
}
export default withRouter(connect(
  mapStateToProps,
  { getData, setCategory }
)(Projectsgrid));
