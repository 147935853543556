import React, { Component } from "react";
import { connect } from "react-redux";
import { getData, getPages, setType, getCategories,updateVotes, addVotes } from "../actions/index";



export class VoteButton extends Component {


  constructor(props) {
    super(props);
        // this.update = this.update.bind(this)
  }

  state = {
    voted: this.checkVoted(),
  };

  checkVoted(){
let voted = false
      let cachedVotes = JSON.parse(localStorage.getItem("ExcellenceVotes"));

      for(let project of cachedVotes){
        if(project[0] === this.props.project.id){
            voted = true
  }
      }
      return voted

  }



  vote(el,projectID){
      if(!this.state.voted){
          this.setState({"voted":true})
          this.props.addVotes(this.props.project.id,localStorage.getItem("ExcellenceID"))

      }else{
        let cachedVotes = JSON.parse(localStorage.getItem("ExcellenceVotes"));
        let selectedVote = cachedVotes.filter(item => item[0] === this.props.project.id)
        cachedVotes = cachedVotes.filter(item => item[0] !== this.props.project.id)

        localStorage.setItem('ExcellenceVotes', JSON.stringify(cachedVotes));
        this.props.updateVotes(selectedVote[0][1])
        this.setState({"voted":false})
      }

  }

render() {

//   if(!this.state.votes){
//   let cachedVotes = JSON.parse(localStorage.getItem("ExcellenceVotes"));
//
//   for(let project of cachedVotes){
//     if(project === this.props.project.id){
//       this.setState({"voted":true})
//     }
//   }
// }
var current = new Date();
var expiry  = new Date("May 10, 2019 12:00:00")

if(current.getTime()>expiry.getTime()){

let Subject = this.props.project.title.replace(" ", "%20");
let Body = "Hi,\r\nI would like to get some more information on the " + this.props.project.title + " project."
let Email = "mailto:Sharon.Madden@aecom.com?subject=Excllence%20awards%20"+Subject+"%20information%20request&body="+encodeURIComponent(Body)
  return(

<div className="button-wrapper" data-fade-in>
<button className="btn"><a href={Email}>Contact</a></button>
</div>

)
}else{
  return(

<div className="button-wrapper" data-fade-in>
    <button className={this.state.voted ? "btn btn--vote voted":"btn btn--vote" }  onClick={this.vote.bind(this,this.props.project.id)} ><span className="icon icon--plus"></span> Vote as People's Choice</button>
</div>

)

}

}

}


function mapStateToProps(state) {
  return {
    categories: state.categories,
    pages: state.pages,
    projects: state.projects,
    app_type: state.app_type
  };
}
export default connect(
  mapStateToProps,
  { getData, getPages, setType, getCategories,updateVotes, addVotes  }
)(VoteButton);
