import React, { useRef } from "react"
import { Link } from "react-router-dom"
import { Flipped } from "react-flip-toolkit"
import anime from "animejs"
import { connect } from "react-redux";
import VoteButton from "./voteButton.js"
import VideoCheck from "./videoCheck.js"
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';


let targetElement = null;


const onComplete = el => {

  // prevent scroll weirdness
  // el.firstElementChild.style.backgroundColor = '#fff'
  anime({
    targets: [...el.querySelectorAll("[data-fade-in]")],
    opacity: [0, 1],
    translateY: [250, 0],
    delay: (el, i) => i * 70 + 200,
    easing: "easeOutSine",
    duration: 250
  })
}

const onStart = el => {
    disableBodyScroll(el);


  ;[...el.querySelectorAll("[data-fade-in]")].forEach(
    el => (el.style.opacity = "0")
  )
}

const onExit = el => {
   enableBodyScroll(el);
       clearAllBodyScrollLocks();
  return anime({
    targets: [
      ...el.querySelectorAll("[data-fade-in]")
    ],
    opacity: 0,
    easing: "easeOutSine",
    duration: 350,
    delay: anime.stagger(20)
  }).finished
}








function DetailPage({

//   match: { params: { set, category } = {} },
//   location,projects,pages,strapiRoot
// })

  match: { params: { set } = {} },
  location,projects,pages,strapiRoot,category
}) {


  function vote(el,id){
    console.log(el,id)
  }



if(location.pathname.includes("page")){

  let Page ="";
    for (let page of pages){
      if(location.pathname.includes(page.title.replace(new RegExp(" ", 'g'), "_").replace(new RegExp("/", 'g'), ""))){
         Page = page
      }
    }
    const elementRef = useRef(null)

  return (
    <Flipped
      flipId={'page/' +Page.title.replace(new RegExp(" ", 'g'), "_")}
      componentId="setPage"
      onComplete={onComplete}
      onStart={onStart}
    >

    <div className="page david-message" ref={elementRef}>
        <Flipped inverseFlipId={'page/' +Page.title.replace(new RegExp(" ", 'g'), "_")}>
        <Flipped
              flipId={`${set}-count`}
              translate
            >

                        <div className="page__inner">
                            <div className="page__content">
                                <div className="page__header">
                                    <Link data-fade-in className="page__close" to={{
                                      pathname: "/",
                                      search: location.search,
                                      state: {
                                        animate: () => onExit(elementRef.current)
                                      }
                                    }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                            <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
                                            <path d="M0 0h24v24H0z" fill="none"/>
                                        </svg>
                                    </Link>
                                    <h2 data-fade-in>{Page.title}</h2>
                                </div>
                                <div className="page__body">
                                    <p data-fade-in>{Page.content}</p>

                                    <img data-fade-in className="responsive-img" src={`${strapiRoot + Page.image.url}`} border="0"/>
                                </div>
                            </div>
                        </div>



        </Flipped>

        </Flipped>

        </div>
    </Flipped>

  )
}else{



let Project ="";

///sort out matching errror there

set  = set.split("?")
  for (let project of projects){

    if(project.title.replace(new RegExp(" ", 'g'), "_").replace(new RegExp("/", 'g'), "") === set[0]){
       Project = project
    }
  }


let Text = Project.project_text




  if(Project.alt_text_category !== undefined){
      if(Project.alt_text_category.Category_title  === category){

       Text = Project.project_alt_text
      }
    }

  const elementRef = useRef(null)
  return (
    <Flipped
      flipId={set}
      componentId="setPage"
      onComplete={onComplete}
      onStart={onStart}
    >

    <div className="page" ref={elementRef}>
        <Flipped inverseFlipId={set}>
        <Flipped
              flipId={`${set}-count`}
              translate
            >

                        <div className="page__inner">
                            <div className="page__content">
                                <div className="page__header">
                                    <Link data-fade-in className="page__close" to={{
                                      pathname: "/",
                                      search: location.search,
                                      state: {
                                        animate: () => onExit(elementRef.current)
                                      }
                                    }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                            <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
                                            <path d="M0 0h24v24H0z" fill="none"/>
                                        </svg>
                                    </Link>
                                    <h2 data-fade-in>{Project.title}</h2>
                                    <VoteButton project={Project}/>
                                </div>
                                <div className="page__body">
                                    <p data-fade-in>{Text}</p>
                                    <VideoCheck project={Project}/>

                                </div>
                            </div>
                        </div>



        </Flipped>

        </Flipped>

        </div>
    </Flipped>
  )

}
}

function mapStateToProps(state) {
  return {
    projects: state.projects,
    strapiRoot : state.strapiRoot,
    setCategory : state.category
  };
}

export default connect(
  mapStateToProps
)(DetailPage);
