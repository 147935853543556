// src/js/actions/index.js
// import {APP_TYPE,APP_NAME } from "../constants/action-types";
import axios from 'axios';
import store from '../stores';
import _ from 'underscore';
const appUrl = store.getState().strapiRoot
const projectsEndPoint = `${appUrl}/projects`;
const pagesEndPoint = `${appUrl}/pages`;
const votesEndPoint = `${appUrl}/votes?_limit=10000`



export function setType(payload) {
  return { type: "APP_TYPE", payload }
};




export function getData() {
  return function(dispatch) {
    dispatch({ type: "DATA_LOADING" });
    return axios.get(projectsEndPoint)
        .then((response) => {
        dispatch({ type: "DATA_LOADED", payload: response.data });
      }).catch((error) => {
        dispatch({ type: "DATA_ERROR", payload: error });
});
  };

}

export function setCategory(category) {
  return function(dispatch) {
    dispatch({ type: "CATEGORY_SET", payload: category });

  };

}

export function getCategories() {
    return function(dispatch) {
  let category = []

        for(let project of store.getState().projects){

          for(let categories of project.categories){
                category.push(categories)
          }


        }
        category = _.uniq(category,'id')

        category =   _.sortBy(category, 'Category_title');

    dispatch({ type: "CATEGORIES_LOADED", payload: category });
  };
}


export function getPages() {
  return function(dispatch) {
    dispatch({ type: "PAGES_LOADING" });
    return axios.get(pagesEndPoint)
        .then((response) => {
          dispatch({ type: "PAGES_LOADED", payload: response.data });
      }).catch((error) => {
        dispatch({ type: "PAGES_ERROR", payload: error });
});
  };
}


export function getVotes() {

  return function(dispatch) {
    dispatch({ type: "VOTES_LOADING" });
    return axios.get(votesEndPoint)
      .then((response) => {
      // console.log(response.data)
      dispatch({ type: "VOTES_LOADED", payload: response.data });
      }).catch((error) => {
        dispatch({ type: "VOTES_ERROR", payload: error });
});
  };

}

export function addVotes(project,user){


  return function(dispatch) {

    dispatch({ type: "VOTES_UPDATING" });

          return axios.post(`${appUrl}/votes`, {
            user: user,
            project:project

          //
          }).then((response) => {

            let cachedVotes = JSON.parse(localStorage.getItem("ExcellenceVotes"));
            cachedVotes.push([project,response.data.id])
            localStorage.setItem('ExcellenceVotes', JSON.stringify(cachedVotes));

              // console.log(" sucess", response)

                dispatch({ type: "VOTES_UPDATED", payload: response.data });


            }).catch((error) => {

              dispatch({ type: "VOTES_ERROR", payload: error });

      });

  };

}


export function updateVotes(id){

// console.log(id)


  return function(dispatch) {

    dispatch({ type: "VOTES_UPDATING" });


        return axios.delete(`${appUrl}/votes/` + id)
              .then((response) => {
              //
              // console.log("deleted", response)

                dispatch({ type: "VOTES_UPDATED", payload: response.data });


            }).catch((error) => {

              dispatch({ type: "VOTES_ERROR", payload: error });

      });

  };

}
