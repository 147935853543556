import React, { Component } from "react";
import { connect } from "react-redux";
import { getData, getPages, setType, getCategories } from "../actions/index";
import { Route, withRouter } from "react-router-dom"
import Projectsgrid from "./projectsgrid.js"
import DetailPage from "./detailpage.js"
import Page from "./page.js"
import Smoothscroll from 'smoothscroll-polyfill';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

import "../css/app.css";

Smoothscroll.polyfill();



export class Main extends Component {
  targetElement = null;

  constructor(props) {
    super(props);
        // this.update = this.update.bind(this)
  }

  state = {
    surveyed: 0,
    navigate: false,
  };

  componentDidMount() {
        if(this.props.projects.length === 0){
    this.props.getData();
  }



  }

    componentDidUpdate() {
      if(this.props.categories.length === 0){
      this.props.getCategories();
    }
      if(this.props.pages.length === 0){
      this.props.getPages();
      }

    }

  componentWillReceiveProps(nextProps) {

  if(nextProps.value !== this.props.value) {

    this.setState({value: nextProps.value});
  }

}

handleShow(i) {
    this.setState({index: i});
    this.refs[i].scrollIntoView({block: 'start', behavior: 'smooth'});
  }

  navigate = set => {
    this.props.history.push({
      pathname: `/${set.replace(/\s/g, "-")}`,
      search: this.props.location.search
    })
  }








  render() {
      const { match, location, history } = this.props
    if(this.props.pages.length !== 0 && this.props.projects.length !== 0 && this.props.categories.length !== 0 ){
    return (
      <div className="site-container layout--column">
          <div className="content layout--column flex">
            <div className="sub-content layout--row">
              <div className="nav-content-wrapper layout--row flex">
                <div className="nav-content layout--row flex">

                            <div className="logos">
                                    <div className="aecom-logo">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 87 20">
                                            <path className="logo-path" d="M13.8 19.2h4.5L14 0.4h-5.1L0 19.2h4.7l1.5-3.7h6.8L13.8 19.2zM8 11.4l2.7-6.5h0l1.4 6.5H8zM32.2 14.8H19l-0.7 4.4h13.2L32.2 14.8zM33.7 4.8H20.5l0.7-4.4h13.2L33.7 4.8zM31 12H19.4l0.7-4.4h11.6L31 12zM46.1 18.9c-1.3 0.6-3.3 0.7-4.2 0.7 -5.3 0-8.9-3.9-8.7-9.4C33.4 4.5 37.9 0 43.3 0c2 0 3.5 0.5 5.4 1.6l-0.9 4.5c-1.2-1-2.6-1.5-4.1-1.5 -3.3 0-5.7 2.3-5.8 5.5 -0.1 3 1.9 5.1 4.9 5.1 0.9 0 2.8-0.1 4.1-0.7L46.1 18.9zM58 0c5 0.4 8.2 4.5 7.6 9.9 -0.6 5.7-5.1 9.9-10.3 9.5 -5-0.4-8.1-4.5-7.6-9.9C48.3 3.9 52.9-0.3 58 0M61.1 10.4c0.7-3.2-0.5-5.7-2.9-6.1 -2.6-0.5-5 1.5-5.8 4.9 -0.7 3.2 0.5 5.7 2.9 6.2C58 15.8 60.4 13.8 61.1 10.4M81.9 19.2l0.1-11 -5.5 11h-2.4l-2-11L68.9 19.2h-4.7l5.9-18.8h4.2l2.2 10.6 5.4-10.6h4.3l0.4 18.8H81.9z"></path>
                                        </svg>
                                    </div>
                                    <div className="awards-logo">
                                        <h1 className="awards__title">UK &amp; Ireland<br/><span>Excellence<br/>Awards</span><br/>2019</h1>
                                    </div>
                                </div>

                                <Page page="David_Barwell" navigate={this.navigate}/>


                                <nav className="categories-nav flex" ref="categories">
                                  <h2 className="categories-nav__title">Categories</h2>
                                    <ul className="categories-nav__list">
                                    {this.props.categories.map(el => (
                                      <li className="categories-nav__item" key={el.id}>
                                        <div onClick={this.handleShow.bind(this, el.Category_title.replace(new RegExp(" ", 'g'), "_").replace(new RegExp("/", 'g'), ""))} className="categories-nav__action">{el.Category_title}</div>
                                      </li>
                                    ))}
                                    </ul>
                                </nav>

                </div>
              </div>
            </div>
              <div className="main-content">
                    <section className="intro">
                        <p className="intro__text">Thank you to all those who  help to deliver a better world, day in and day&nbsp;out.</p>
                        <div className="section-divider-overlay"></div>
                        <div className="section-background"></div>
                    </section>
                    <section className="categories">
                    {this.props.categories.map((el, index) => (
                      <div id="category-safeguard" key={index} ref={el.Category_title.replace(new RegExp(" ", 'g'), "_").replace(new RegExp("/", 'g'), "")} className="category">
                          <div className="container">
                              <div className="category__meta">
                                  <div className="category__header">
                                      <span className="category__title">{el.Category_title}</span>
                                  </div>
                                  <div className="category__description">
                                    {el.Category_intro}
                                  </div>
                              </div>
                              <Projectsgrid  category={el.Category_title} />
                              <div onClick={this.handleShow.bind(this, "categories")} className="back-to-top button button--arrow" >
                                  Back to top
                              </div>

                          </div>
                      </div>
                    ))}

                    </section>
                    <Route path="/:set/:focusedIcon?" render={(props) => <DetailPage {...props} projects={this.props.projects} pages={this.props.pages} category={this.props.category} />} />


             </div>
          </div>
        </div>

    );
  }else{
        return (
                            <div class="loading">
                            <div className="logos">
                                <div className="aecom-logo">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 87 20">
                                        <path className="logo-path" d="M13.8 19.2h4.5L14 0.4h-5.1L0 19.2h4.7l1.5-3.7h6.8L13.8 19.2zM8 11.4l2.7-6.5h0l1.4 6.5H8zM32.2 14.8H19l-0.7 4.4h13.2L32.2 14.8zM33.7 4.8H20.5l0.7-4.4h13.2L33.7 4.8zM31 12H19.4l0.7-4.4h11.6L31 12zM46.1 18.9c-1.3 0.6-3.3 0.7-4.2 0.7 -5.3 0-8.9-3.9-8.7-9.4C33.4 4.5 37.9 0 43.3 0c2 0 3.5 0.5 5.4 1.6l-0.9 4.5c-1.2-1-2.6-1.5-4.1-1.5 -3.3 0-5.7 2.3-5.8 5.5 -0.1 3 1.9 5.1 4.9 5.1 0.9 0 2.8-0.1 4.1-0.7L46.1 18.9zM58 0c5 0.4 8.2 4.5 7.6 9.9 -0.6 5.7-5.1 9.9-10.3 9.5 -5-0.4-8.1-4.5-7.6-9.9C48.3 3.9 52.9-0.3 58 0M61.1 10.4c0.7-3.2-0.5-5.7-2.9-6.1 -2.6-0.5-5 1.5-5.8 4.9 -0.7 3.2 0.5 5.7 2.9 6.2C58 15.8 60.4 13.8 61.1 10.4M81.9 19.2l0.1-11 -5.5 11h-2.4l-2-11L68.9 19.2h-4.7l5.9-18.8h4.2l2.2 10.6 5.4-10.6h4.3l0.4 18.8H81.9z"></path>
                                    </svg>
                                </div>
                                <div className="awards-logo">
                                    <h1 className="awards__title">UK &amp; Ireland<br/><span>Excellence<br/>Awards</span><br/>2019</h1>
                                </div>
                            </div>
                            <h1 class="loading_title">Loading...</h1>
                            </div>
  );
  }
  }
}

function mapStateToProps(state) {
  return {
    categories: state.categories,
    pages: state.pages,
    projects: state.projects,
    app_type: state.app_type,
    category: state.category
  };
}
export default withRouter(connect(
  mapStateToProps,
  { getData, getPages, setType, getCategories  }
)(Main));
