import React  from 'react';
import uuidv1 from 'uuid/v1'
import { Router } from "react-router"
import {Route, Switch} from "react-router-dom";
import createBrowserHistory from "history/createBrowserHistory"
import { Flipper } from "react-flip-toolkit"
import './App.css';
import store from "./stores/index";
import { connect } from "react-redux";
import { setType } from "./actions/index";
import Error from "./components/error"
// import ProjectSlider from "./components/slider"
// import FOISlides from "./components/foi_slides"
import Dashboard from "./components/dashboard"
// import Graph from "./components/graph"
// import Radial from "./components/radial"
import Main from "./components/main"
// import {subscribeToTimer} from "./actions/connect";



import ReactQueryParams from 'react-query-params';


window.store = store;

const history = createBrowserHistory()
const cachedPush = history.push
// override history.push method to allow to exit animations and delayed FLIP
history.push = args => {
  if (typeof args === "string") {
    return cachedPush(args)
  }
  if (args && args.state && args.state.animate) {
    args.state.animate().then(() => {
      delete args.state.animate
      cachedPush(args)
    })
  } else {
    cachedPush(args)
  }
}


class App extends ReactQueryParams {

  constructor (props) {
    super(props)



  }

componentDidMount(){

  const voteID = localStorage.getItem("ExcellenceID");
    if (!voteID) {
      localStorage.setItem('ExcellenceID', uuidv1());
    }
    const cachedVotes = localStorage.getItem("ExcellenceVotes");
      if (!cachedVotes) {
        var Votes = [];
        localStorage.setItem('ExcellenceVotes', JSON.stringify(Votes));

      }


}

  componentWillReceiveProps(nextProps) {

  if(nextProps.value !== this.props.value) {
    this.setState({value: nextProps.value});
  }

}

    // <Route exact path="/dashboard" component={Dashboard} />

  render() {
    if(this.queryParams.app_type !== undefined){
      this.props.setType(this.queryParams.app_type);
    }





return(
<Router history={history}>
<Switch>
    <Route exact path="/results" component={Dashboard} />
    <Route
      render={({ location, search }) => {
        return (
          <Flipper
            flipKey={location}
            decisionData={{
              location
            }}
          >
            <Main/>
          </Flipper>
        )
      }}
    />

    </Switch>
   </Router>



)



  }
}

function mapStateToProps(state) {
  return {
    strapiRoot : state.strapiRoot,
    app_type: state.app_type
  };
}
export default connect(
  mapStateToProps,
  { setType}
)(App);
