export const DATA_LOADED = "DATA_LOADED";
export const DATA_ERROR = "DATA_ERROR";
export const DATA_LOADING = "DATA_LOADING";
export const APP_TYPE = "APP_TYPE";
export const VOTES_LOADED = "VOTES_LOADED";
export const VOTES_ERROR = "VOTES_ERROR";
export const VOTES_LOADING = "VOTES_LOADING";
export const VOTES_UPDATING = "VOTES_UPDATING";
export const VOTES_UPDATED = "VOTES_UPDATED";
export const PAGES_LOADED = "PAGES_LOADED";
export const PAGES_LOADING = "PAGES_LOADING";
export const PAGES_ERROR = "PAGES_ERROR";
export const CATEGORIES_LOADED = "CATEGORIES_LOADED";
export const CATEGORIES_LOADING = "CATEGORIES_LOADING";
export const CATEGORY_SET = "CATEGORY_SET";
