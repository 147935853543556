import {
DATA_LOADED,
  DATA_ERROR,
DATA_LOADING,
APP_TYPE,
  VOTES_LOADED,
  VOTES_ERROR,
VOTES_LOADING,
  VOTES_UPDATING,
VOTES_UPDATED,
  PAGES_LOADED,
  PAGES_LOADING,
  PAGES_ERROR,
  CATEGORIES_LOADING,
  CATEGORIES_LOADED,
  CATEGORY_SET
} from "../constants/action-types";



const initialState = {
  categories:[],
  projects: [],
  votes: [],
  pages: [],
  error: "",
  app_type: "",
  category: "",
  strapiRoot:"https://apps.aecom-digital.com/excellence"
};



function rootReducer(state = initialState, action) {


  if (action.type === DATA_ERROR) {
    console.log(action.payload)
    return Object.assign({}, state, {
      error: "there was a problem loading the data - " + action.payload
    });

  }

  if (action.type === DATA_LOADED) {
    return Object.assign({}, state, {
      projects: state.projects.concat(action.payload),
      error: ""
    });
  }


    if (action.type === DATA_LOADING) {
      return Object.assign({}, state, {
        error: "LOADING"
      });
    }

    if (action.type === CATEGORIES_LOADING) {
      return Object.assign({}, state, {
        error: "LOADING"
      });
    }
    if (action.type === CATEGORIES_LOADED) {
      console.log("loaded")
      return Object.assign({}, state, {
        categories: state.categories.concat(action.payload),
        error: ""
      });
    }
    if (action.type === CATEGORY_SET) {
      return Object.assign({}, state, {
        category: action.payload
      });
    }

  if (action.type === VOTES_LOADED) {
    return Object.assign({}, state, {
      votes: action.payload,
      error: ""
    });
  }

  if (action.type === PAGES_LOADED) {
    return Object.assign({}, state, {
      pages: action.payload,
      error: ""
    });
  }

  if (action.type === PAGES_LOADING) {
    return Object.assign({}, state, {
      error: "LOADING"
    });
  }

  if (action.type === PAGES_ERROR) {
    return Object.assign({}, state, {
      error: "error"
    });
  }

  if (action.type === VOTES_LOADING) {
    return Object.assign({}, state, {
      error: "LOADING"
    });
  }

  if (action.type === VOTES_ERROR) {
    return Object.assign({}, state, {
      error: "error"
    });
  }




  if (action.type === VOTES_UPDATING) {
    return Object.assign({}, state, {
      update: "UPDATING"
    });
  }
  if (action.type === VOTES_UPDATED) {
    return Object.assign({}, state, {
      update: "UPDATED"
    });
  }



  if (action.type === APP_TYPE) {
    return Object.assign({}, state, {
      app_type: action.payload
    });

  }





  return state;
};








export default rootReducer;
